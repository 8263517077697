<template>
  <div class="c-app">
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
    <LoadSpinner v-if="loadingData" />
  </div>
</template>

<script>
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import LoadSpinner from '../components/LoadSpinner'
import { mapState } from 'vuex'

export default {
  name: 'TheContainer',
  components: {
    TheHeader,
    TheFooter,
    LoadSpinner
  },
  computed: mapState({
    loadingData: state => state.kpiData.loading,
  })
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
